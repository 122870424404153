/*
* leonie_11_2019.scss
* 12-12-2019 - Jelmer Jellema - Spin in het Web B.V.
*/

@use "variables";
@debug "leonie_11_2019";

//////////////////// STYLING OBV Ontwerp LEONIE 11-2019 ////////////////////
/* we gaan uit van bepaalde padding links en rechts. Dit zetten we niet op
de buitenste content, want sommige zaken moeten fullscreen. Het zit vooral
op items e.d.
 */

//SETTINGS (niet global, zeg maar)
//algemene horizontale ruimte
$bp_item_space_hori: 4.5vw;
$bp_item_space_hori_breed: 3 * $bp_item_space_hori;
$bp_item_space_verti: 12px; //wel op pixels


////////////////////////// CONTENT /////////////////////
//de gewone content heeft dus geen padding
ion-content {
  --padding-top: 0;
  --padding-bottom: 0;
  --padding-start: 0;
  --padding-end: 0;
}

////////////////////////// ITEM ////////////////////////////////
/*
De basis van .item (.item-ios en .item-md) is niet lekker. De variabele padding-right wordt vergeten
maar juist toegepast bij avatars. Sowieso gaan de vars enorm de mist n
wij werken sowieso zonder marges in de items. Die duwen tegen de hoofdmarges aan.
 */

ion-item, [ion-item], ion-item.item-block, [ion-item].item-block {
  --padding-top: #{$bp_item_space_verti};
  --padding-bottom: #{$bp_item_space_verti};
  --padding-start: #{$bp_item_space_hori};
  --padding-end: #{$bp_item_space_hori};
  //padding: $bp_item_space_verti $bp_item_space_hori !important; //overrule

  //onze border:
  //border-bottom: var(--bp-border);

  .item-inner {
    border: none !important; //want op het hele item
  }

  //een element met no-border met een item erin is geen border
  //en een item zelf met no-border ook niet
  //attr of class (class is gemakkelijker in conditie)
  &[no-border], [no-border] &, &.no-border, .no-border & {
    border-bottom: none;
  }

  //push helemaal rechts
  &[detail-push], &:not([detail-none]) {
    .item-inner {
      --padding-end: 16px;
      background-position: right 0 center;
    }
  }

  //thumbnail margins
  //niet meer gebruikt?
/*  ion-avatar[item-left], ion-avatar[item-start] {
    margin: 2px $bp_item_space_hori 0 0; //boven een beetje, om uit te lijnen met tekst
  }*/

  //label in een item-wrapper
  .item-inner .input-wrapper .label {
    margin: 0; //we doen dat in het item zelf
  }
}

//en forceer border
[force-border] {
  border-bottom: var(--bp-border) !important;
}

[force-topborder] {
  border-top: var(--bp-border);
}

//buttonrij niet in een item
buttonrij .buttonrijcontainer {
  padding: $bp_item_space_verti $bp_item_space_hori;

  ion-item &, [ion-item] & {
    padding: 0;
  }
}

///zo ook het proefblok
[proefblok] {
  padding: 0 $bp_item_space_hori !important;
  margin: 0 !important;
}

//////itemblock
//let op: ook variabelen op de divider

itemblock {
  ion-item-divider {
    margin: 0 0 2px 0 !important;
    padding: 0 $bp_item_space_hori !important;

    button {
      color: inherit !important;
      margin: 0 !important;
      padding: 0 !important;
    }
  }
}

//select item
[ion-item].item-select, ion-item.item-select {
  .item-inner {
    padding: 0 !important;

    .input-wrapper {
      padding: 0 !important;
    }

    ion-select {
      .select-icon-inner {
        color: white;
      }
    }
  }
}



