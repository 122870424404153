//fonts om te importeren

@font-face {
  font-family: "handschrift";
  font-style: normal;
  font-weight: 400;
  src: url("#{$font-path}/ShadowsIntoLight-Regular_openfont.ttf") format("truetype");
}

//roboto
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

/////////////////// Tekst ////////////////////////


h1 {
  text-align: center;
}

/////kleurtags - overrulebaar in layout
[color-danger] {
  color: red;
}
