/*
* helperstyle.scss
* 12-12-2019 - Jelmer Jellema - Spin in het Web B.V.
*/


////////////////// helpers ///////////////////////
[noscroll]
{
  overflow: hidden;
  --overflow: hidden; //voor de shadow etc
}

[autoscroll]
{
  overflow-x: hidden;
  --overflow-x: hidden;
  overflow-y: auto;
  --overflow-y: auto;
}

[todo] {
 // border-bottom: 1px dotted yellow !important;
}

//displayclasses
.d-block {
  display: block !important;
}

//centered pagina
[centered] {
  text-align: center;
}

[midden] {
  display: block !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

[klein] {
  font-size: smaller !important;
}

[groot] {
  font-size: larger !important;
}


///
ion-content {
  // background: url("#{$img-path}/achtergrond.jpg");
  background-size: cover;
}

//ion-col gebaseerd op children grootte, same width
ion-grid[fix-cols] ion-col, ion-row[fix-cols] ion-col {
  flex: 1 1 0;
  width: 0; //maak grootte afhankelijk van de inhoud
}

ion-item[wrap] ion-label, ion-label[wrap] {
  white-space: normal;
}
