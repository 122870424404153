//Huisstijl voor de app - importeert de andere files
//gebruik @use "huisstijl" om hem beschikbaar te krijgen

@debug "Beerpro Huisstijl";

//// app-root in een desktopbrowser

@media(min-width: 1024px) {
  ion-app {
    width: 1024px;
    margin: 0 auto;
  }
}

@import "paden";
@import "letter";
@import "helpers";
@import "elementen";
@import "leonie_11_2019_style";
