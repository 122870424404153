// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {

  //kleuren genereren via https://ionicframework.com/docs/theming/color-generator
  //of https://ionicframework.com/docs/theming/colors#new-color-creator
  /** beerpro-kleuren **/
  --bp-kleur-donker: #2A374A;
  //gegenereerd
  --ion-color-bp_donker: #2A374A;
  --ion-color-bp_donker-rgb: 42, 55, 74;
  --ion-color-bp_donker-contrast: #ffffff;
  --ion-color-bp_donker-contrast-rgb: 255, 255, 255;
  --ion-color-bp_donker-shade: #253041;
  --ion-color-bp_donker-tint: #3f4b5c;

  --bp-kleur-fadetext: #C8D7E8;
  --bp-kleur-geel: #FED05D;
  --bp-kleur-blauw_donker: #2C5279;
  --bp-kleur-blauw_bg: #7995BA;
  --bp-kleur-oranjeknop: #E28830;
  --bp-kleur-viltje: #29374A; //tekst en icons op viltje
  --bp-kleur-accepteren: #2C5279;
  --bp-kleur-geaccepteerd: #FED05D;
  --bp-kleur-weigeren: #C6342F;
  --bp-kleur-geweigerd: #EE8133;
  --bp-kleur-inchecken: #FED05D;
  --bp-kleur-ingecheckt: #FFFFFF;
  --bp-kleur-donkerrood: #732F3A;
  --bp-kleur-special-bg: #F7F7F7;
  --bp-kleur-special-fg: #222222;

  //standaard border voor lijntjes etc
  --bp-border: 2px solid var(--bp-kleur-blauw_bg);

  //basis
  --ion-background-color: var(--ion-color-bp_donker);
  --ion-background-color-rgb: var(--ion-color-bp_donker-rgb);
  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255, 255, 255;

  --ion-font-family: "Roboto", "Helvetica Neue", sans-serif;

  --ion-color-primary: #7995BA;
  --ion-color-primary-rgb: 121, 149, 186;
  --ion-color-primary-contrast: #ffffff; //overrule tov de generator!
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #6a83a4;
  --ion-color-primary-tint: #86a0c1;

  --ion-color-secondary: #FED05D;
  --ion-color-secondary-rgb: 254, 208, 93;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-shade: #e0b752;
  --ion-color-secondary-tint: #fed56d;

  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  --ion-color-danger: #C6342F;
  --ion-color-danger-rgb: 198, 52, 47;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #ae2e29;
  --ion-color-danger-tint: #cc4844;

  --ion-color-dark: #2C5279;
  --ion-color-dark-rgb: 44, 82, 121;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #27486a;
  --ion-color-dark-tint: #416386;

  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  --ion-color-light: #C8D7E8;
  --ion-color-light-rgb: 200, 215, 232;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #b0bdcc;
  --ion-color-light-tint: #cedbea;
}

//de rest is er uit, we definiëren in principe alles op :root, en eventueel apart dingen op .ios of .md.
