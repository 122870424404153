/**
globale styles voor elementen
 */
@use "variables";

a {
  text-decoration: none;
}

//de blocker
ion-loading.apiblocker {
  --background: 'transparent';
  --spinner-color: var(--bp-kleur-geel);
}

//toast
ion-toast {
  --background: var(--bp-kleur-blauw_bg);
  --color: white;
  --button-color: white;
}

ion-toast.foutmelding {
  .toast-container {
    background-color: var(--bp-kleur-geel);

    .toast-message {
      color: black;
    }
  }
}


//avatar:
ion-avatar {
  width: 56px;
  height: 56px;

  img {
    width: 100%;
    height: 100%;
    overflow: hidden;
    object-fit: contain;
    border: 2px solid white; //witte border
  }
}

//thumbnail
ion-thumbnail {
  // --size: 56px; //wordt volgens mij niet meer gebruikt: we werken hier omheen
}

//fix clear buttons in een ion-item
ion-item button[clear], [ion-item] button[clear] {
  color: var(--ion-text-color);
}

//ion-item verticaal te centreren e.d. door --bp-itemvertalign te zetten
ion-item {
  align-items: var(--bp-itemvertalign, flex-start) !important;
}


//biertje op pagina's op lege regel
.bierglas {
  width: 15vw;
  height: 15vh;
  margin: 8px auto;
  background: url("#{$img-path}/bierglas.svg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

////////////////////////////////

//boxed input velden
ion-input[boxed] {
  --background: var(--bp-kleur-special-bg);
  --color: var(--bp-kleur-special-fg);
  --placeholder-font-style: italic;
  margin-top: 4px;
  margin-bottom: 4px;
  padding: 8px;
  border-radius: 5px;
}

ion-item-divider {
  --background: var(--bp-kleur-blauw_bg);
  --color: var(--ion-text-color);
  font-weight: normal;
  --inner-padding-top: 4px;
  --inner-padding-bottom: var(--inner-padding-top);
}

//een header voor een item
ion-card {
  margin: 10px;
  box-shadow: 0 1px 2px var(--bp-kleur-blauw_bg);

  ion-card-header {
    /* font-size: 1.7rem;
     border-bottom: 1px dotted #eee;*/
    //text-align: center;
    background-color: var(--bp-kleur-blauw_bg);
    color: white;
    padding: 4px;

    ion-avatar {
      display: inline-block;
      vertical-align: middle;
      margin-right: 8px;
      height: 40px;
      width: 40px;
    }
  }

  ion-card-content, .card-content-ios, .card-content-md, .card-content {
    //padding: 0;
    padding-inline-start: 0;
    padding-inline-end: 0;
    padding-bottom: 0;

    ion-item  {
      --inner-border-width: 0;
     /* --padding-bottom: 10px;
      --padding-top: 10px;
      --padding-start: 16px;
      --padding-end: 16px;
      --inner-padding-top: 0;*/
    }
  }
}
